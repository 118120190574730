import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import { useGetters } from '@u3u/vue-hooks'

/*
 * Dayjs instance for easy date manipualtions
 *
 * @param timestamp - Unix timestamp
 */

const { currentLang } = useGetters(['currentLang'])
export const date = (timestamp: string): Dayjs => {
  dayjs.locale(currentLang.value === 'de' ? 'de' : 'fr')

  return dayjs(timestamp)
}

export const intToDate = (int: number): string => {
  const str = int.toString().replace(/(\d\d\d\d)(\d\d)(\d\d)/, '$1-$2-$3')
  const strDate = dayjs(str).locale(currentLang.value).format('D MMMM YYYY')

  return strDate
}
