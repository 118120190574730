
















































import { defineComponent, computed } from '@vue/composition-api'

import { date } from '@/composables/date'

export default defineComponent({
  name: 'main-title',
  props: {
    title: String,
    button: Object,
    hasSeparator: Boolean,
    publicationDate: String,
  },
  setup(props) {
    const customClass = computed(() => [{ 'has-button': props.button }])

    return {
      customClass,
      date,
    }
  },
})
