








































































































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'

import { defineComponent, onBeforeMount, onMounted } from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'

import FaqContact from '@/components/faq/Contact.vue'
import MainTitle from '@/components/MainTitle.vue'
import FaqsPanel from '@/components/faq/FaqsPanel.vue'
import TipsNew from '@/components/TipsNew.vue'

export default defineComponent({
  name: 'faq-details',
  components: {
    FaqContact,
    MainTitle,
    Breadcrumb,
    TipsNew,
    FlexibleContents,
  },
  setup(_props, ctx) {
    // NOTE: `children` moved to content
    const { content } = useGetters(['content', 'cookiebotPage'])
    const state = useState('faq', ['i18n'])
    const actions = useActions('faq', ['FETCH_I18N'])

    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    // Methode pour afficher le panel
    const showFaqPanel = () => {
      resetPanel()
      showPanel({
        component: {
          template: FaqsPanel,
        },
        content: {},
      })
    }
    // L'event qui permet d'ouvrir le panel
    ctx.root.$on('tip:btn-click', () => showFaqPanel())
    // partie i18n , permettant de recuperer les traductions
    onBeforeMount(() => {
      actions.FETCH_I18N()
    })

    onMounted(() => {
      function wrap(top, selector, bottom) {
        const matches = document.querySelectorAll('.wysiwyg table')
        for (let i = 0; i < matches.length; i++) {
          matches[i].outerHTML = top + matches[i].outerHTML + bottom
        }
      }

      // eslint-disable-next-line quotes
      wrap("<div class='table-wrapper'>", '.getWrapped', '</div>')
    })

    return {
      content,
      showFaqPanel,
      ...useGetters(['chrome']),
      i18n: state.i18n,
    }
  },
})
